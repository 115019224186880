#root {
    width: 100vw;
    height: 100vh;
}

.base-page {
    background: #f5f5f5;
    padding: 24px;
    width: 100%;
    overflow: auto;
}

.base-page-content {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.center-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.input-read-only {
    background-color: #eceaea !important;
}

.input-read-only input {
    background-color: #eceaea !important;
}

.full-height {
    height: 100% !important;
}

.ant-form-item-label {
    padding: 0 !important;
}

.tag-large {
    font-size: 14px !important;
    line-height: 1.5715 !important;
    padding: 4px 11px !important;
}

.input-text-break-all {
    word-break: break-all;
}
